import { RouteNames } from 'RouteNames'
import { produce } from 'immer'
import { CustomPackage, Selections } from 'types/onboardingTypes'
import { BodyFastAction } from '.'
import { ActionType } from './ActionTypes'
import { FirebaseError } from 'firebase/app'

export const DEFAULT_MEALTIMES = {
  breakfast: '0700',
  lunch: '1200',
  dinner: '1900',
  breakfastWeekend: '0700',
  lunchWeekend: '1200',
  dinnerWeekend: '1900'
}

export interface AppState {
  appBlocked: boolean
}

export interface IUpdateUserSuccessAction { type: ActionType.UPDATE_USER_SUCCESS, user: IUser, newUserCreated: boolean }
export interface IUpdateUserChangesetRequestAction { type: ActionType.UPDATE_USER_CHANGESET_REQUEST, changeset: Partial<IUser> }
export interface IStartUpSuccessAction { type: ActionType.START_UP_SUCCESS }
export interface IResolvePurchaseAction { type: ActionType.RESOLVE_PURCHASE }
export interface IAnonymousSignInRequestAction { type: ActionType.ANONYMOUS_SIGN_IN_REQUEST, newsletterAccepted: boolean /* only for logging */, resolve: () => void, reject: (e: Error) => void }
export interface IUpdateUserRequestAction { type: ActionType.UPDATE_USER_REQUEST, user: IUser }
export interface ISaveOnboardingDataRequestAction { type: ActionType.SAVE_ONBOARDING_DATA_REQUEST, onboardingData: Selections, email: string, newsletterAccepted: boolean, resolve: () => void, reject: (e: Error) => void }
export interface ISetAppBlockedAction { type: ActionType.SET_APP_BLOCKED }
export interface ISetAppUnblockedAction { type: ActionType.SET_APP_UNBLOCKED }
export interface ILogoutRequestAction { type: ActionType.LOG_OUT_REQUEST, nextRouteName?: RouteNames }
export interface ISignUpWithEmailRequestAction { type: ActionType.SIGN_UP_WITH_EMAIL_REQUEST, email: string, password: string, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface ISignInWithFutokenAction { type: ActionType.SIGN_IN_WITH_FUTOKEN_REQUEST, futoken: string, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface ISignInWithGoogleOneTapAction { type: ActionType.SIGN_IN_WITH_GOOGLE_ONE_TAP, idToken: string, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface ISignInWithEmailRequestAction { type: ActionType.SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST, email: string, password: string, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface ISignInWithGoogleRequestAction { type: ActionType.SIGN_IN_WITH_GOOGLE_REQUEST, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface IRegisterWithGoogleRequestAction { type: ActionType.REGISTER_WITH_GOOGLE_REQUEST, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface ISignInWithAppleRequestAction { type: ActionType.SIGN_IN_WITH_APPLE_REQUEST, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface IUpgradeUserWithGoogleRequestAction { type: ActionType.UPGRADE_USER_WITH_GOOGLE_REQUEST, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface IUpgradeUserWithAppleRequestAction { type: ActionType.UPGRADE_USER_WITH_APPLE_REQUEST, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface IRegisterWithAppleRequestAction { type: ActionType.REGISTER_WITH_APPLE_REQUEST, onSuccess: () => void, onError: (error: FirebaseError) => void }
export interface ICheckEmailExistsRequestAction { type: ActionType.CHECK_EMAIL_EXISTS_REQUEST, email: string, resolve: (emailExists: boolean) => void }
export interface IResetPasswordRequestAction { type: ActionType.RESET_PASSWORD_REQUEST, email: string, onSuccess: () => void, onError: (errorMessage: string) => void }
export interface ICreateStripeSessionAction { type: ActionType.CREATE_STRIPE_SESSION_REQUEST, selectedPackage: CustomPackage, packages: CustomPackage[], onSuccess: (sessionId: string) => void, onError: (error: string) => void }
export interface IHandleNavigatingToPostOnboardingRouteAction { type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE }
export interface IRegisterWithEmailAndPassword { type: ActionType.REGISTER_WITH_EMAIL_AND_PASSWORD, email: string, password: string, name: string, resolve: () => void, reject: (error: FirebaseError) => void }
export interface ISignInWithEmailAndPasswordFailed { type: ActionType.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED, error: FirebaseError }
export interface ISignInWithEmailAndPasswordSuccess { type: ActionType.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS }

export type AppAction =
  | IUpdateUserSuccessAction
  | IUpdateUserChangesetRequestAction
  | IStartUpSuccessAction
  | IResolvePurchaseAction
  | IAnonymousSignInRequestAction
  | IUpdateUserRequestAction
  | ISaveOnboardingDataRequestAction
  | ILogoutRequestAction
  | ISetAppBlockedAction
  | ISetAppUnblockedAction
  | ISignInWithFutokenAction
  | ISignUpWithEmailRequestAction
  | ISignInWithGoogleOneTapAction
  | ISignInWithEmailRequestAction
  | ISignInWithGoogleRequestAction
  | ISignInWithAppleRequestAction
  | ISignInWithEmailAndPasswordFailed
  | ISignInWithEmailAndPasswordSuccess
  | ICheckEmailExistsRequestAction
  | IResetPasswordRequestAction
  | ICreateStripeSessionAction
  | IHandleNavigatingToPostOnboardingRouteAction
  | IRegisterWithEmailAndPassword
  | IRegisterWithGoogleRequestAction
  | IRegisterWithAppleRequestAction
  | IUpgradeUserWithGoogleRequestAction
  | IUpgradeUserWithAppleRequestAction

const INITIAL_STATE: AppState = {
  appBlocked: false
}

export const AppReducer = (state: AppState = INITIAL_STATE, action: BodyFastAction): AppState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_APP_UNBLOCKED: {
        return { ...state, appBlocked: false }
      }

      case ActionType.SET_APP_BLOCKED: {
        return { ...state, appBlocked: true }
      }

      default:
        break
    }
  })
}
