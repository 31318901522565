import { combineReducers } from 'redux'
import { AppReducer, AppState } from './AppRedux'
import { createBrowserHistory } from 'history'
import { connectRouter } from 'connected-react-router'
import { StartupReducer, StartupState } from 'Reducers/StartupRedux'
import { LoginReducer, LoginState } from 'Reducers/LoginRedux'
import { PaymentReducer, PaymentState } from 'Reducers/PaymentPersistingRedux'
import { WellhubReducer, WellhubState } from 'Reducers/WellhubRedux'

export interface IRootState {
  router: any
  App: AppState
  startup: StartupState
  login: LoginState
  payment: PaymentState
  wellhub: WellhubState
}

export const history = createBrowserHistory()

export const rootReducer = combineReducers<IRootState>({
  router: connectRouter(history),
  App: AppReducer,
  startup: StartupReducer,
  login: LoginReducer,
  payment: PaymentReducer,
  wellhub: WellhubReducer
})
