// 5 days until a cancelled subscription gets deactivated (1 day on non-production systems)
const COOLDOWN_PERIOD: number = process.env.NODE_ENV !== 'production' ? 1 * 24 * 60 * 60 * 1000 : 5 * 24 * 60 * 60 * 1000

export const getGoalWeightDate = (goalWeight: IUserWeight, currentWeight: IUserWeight) => {
  let weightLossPerWeek = 0

  if (currentWeight.inKg.value - goalWeight.inKg.value < 5) {
    weightLossPerWeek = 0.5
  } else {
    weightLossPerWeek = 0.75
  }

  const totalWeeks = Math.ceil((currentWeight.inKg.value - goalWeight.inKg.value) / weightLossPerWeek)

  const goalDate = new Date()
  goalDate.setDate(goalDate.getDate() + totalWeeks * 7)

  return goalDate
}

// Merge two objects with the same structure without overwriting the values
export function deepMerge(obj1: any, obj2: any) {
  const output = { ...obj1 }

  Object.keys(obj2).forEach(key => {
    // eslint-disable-next-line no-prototype-builtins
    if (obj2[key] && typeof obj2[key] === 'object' && obj1.hasOwnProperty(key) && obj1[key] && typeof obj1[key] === 'object') {
      output[key] = deepMerge(obj1[key], obj2[key])
    } else {
      output[key] = obj2[key]
    }
  })

  return output
}

export const isUserSubscribed = (user: IUser): boolean => {
  if (user.wellhub?.status === 'active') {
    return true
  }

  if (!user.subscription) {
    return false
  }

  const lastIapLogEntry = user.iapLog?.slice(-1)[0]

  if (
    lastIapLogEntry?.type === 'SUBSCRIPTION_EXPIRED' || // ANDROID
    lastIapLogEntry?.type === 'SUBSCRIPTION_PAUSED' || // ANDROID
    lastIapLogEntry?.type === 'SUBSCRIPTION_ON_HOLD' || // ANDROID
    lastIapLogEntry?.type === 'EXPIRED' || // IOS
    lastIapLogEntry?.type === 'GRACE_PERIOD_EXPIRED' || // IOS. See docs: https://developer.apple.com/documentation/appstoreservernotifications/notificationtype
    (lastIapLogEntry?.type === 'DID_FAIL_TO_RENEW' && lastIapLogEntry?.subtype !== 'GRACE_PERIOD')
  ) {
    return false
  }

  return !!user.subscription
}

export const kilogramsToPounds = (kg: number): number => kg * 2.2046226218487757

export const getFormattedWeight = (weightInKg: number, unitSystem: IUnitSystem): string | null => {
  const unit = unitSystem === 'cm/kg' ? 'kg' : 'lbs'
  const convertedWeight = unitSystem === 'cm/kg' ? Math.floor(weightInKg) : Math.floor(kilogramsToPounds(weightInKg))
  return `${convertedWeight}${unit}`
}

export const calculateBMI = (height: number, weight: number) => {
  const heightInMeters = height / 100

  return weight / (heightInMeters * heightInMeters)
}

export const mapAgeToAgeRange = (age?: number | null): AgeRange | null => {
  if (!age) {
    return null
  }
  if (age < 18) {
    return 'age_17_and_under'
  } else if (age >= 18 && age <= 29) {
    return 'age_18_to_29'
  } else if (age >= 30 && age <= 39) {
    return 'age_30_to_39'
  } else if (age >= 40 && age <= 49) {
    return 'age_40_to_49'
  } else {
    return 'age_50_and_up'
  }
}
