import { all, fork } from 'redux-saga/effects'

import { AppSagas } from './AppSagas'
import { StartupSagas } from './StartupSagas'
import { LoginSagas } from 'Sagas/LoginSagas'
import { NavigationSagas } from 'Sagas/NavigationSagas'
import { ManageSubscriptionSagas } from 'Sagas/ManageSubscriptionSagas'
import { PaymentSagas } from 'Sagas/PaymentSagas'
import { WellhubSagas } from 'Sagas'
// import { RemoteConfigSagas } from 'Sagas/RemoteConfigSagas'

// Some word of caution here
// https://reactrouter.com/web/guides/deep-redux-integration
// Integrating redux with react-router is not oficially supported and discouraged, and it is easy to see why
// keeping the navigation state in your own app state breaks separation of concerns
// as such unless there is a major reason to do so I'm keeping the
// navigation state and the app state separate, if your sagas need to navigate
// you need to pass the `history` object to the saga via the action itself
// it is a bit verbose but should prevent abusing the router

export function * rootSaga() {
  yield all([
    fork(AppSagas),
    fork(StartupSagas),
    fork(LoginSagas),
    fork(NavigationSagas),
    fork(ManageSubscriptionSagas),
    fork(PaymentSagas),
    fork(WellhubSagas)
    // fork(RemoteConfigSagas)
  ])
}
