import React, { FormEvent, useState, useMemo } from 'react'
import styled from 'styled-components'
import { images } from 'images'
import IconInput from 'Components/IconInput'
import { FaEnvelope, FaLock, FaUser } from 'react-icons/fa'
import { isValidEmail, MAX_NAME_LENGTH, validateEmail, validateName, validatePassword } from 'Lib'
import Button from 'Components/Button'
import { ClipLoader } from 'react-spinners'
import { useDispatch } from 'store'
import { ActionType, Selectors } from 'Reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const MaxWidth = styled.div`
  max-width: 400px;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing.small};
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  position: relative;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-left: 12px;
  margin-right: 12px;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.green50};
  font-size: ${({ theme }) => theme.fontSizes.xLarge};
  font-style: normal;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.green50};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const Divider = styled.div`
  width: 2px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 15px;
  z-index: 1;
`

const BodyFastLogo = styled.img`
  margin-top: 4px;
  width: 120px;
  z-index: 1;
`

const WellhubLogo = styled.img`
  width: 120px;
  z-index: 1;
`

const Ellipse = styled.div`
  position: absolute;
  top: -92px;
  width: 485px;
  height: 290px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green600};
  opacity: 0.32;
  z-index: 0;
`

const Error = styled.span`
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.red800};
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 400;
  line-height: 150%; /* 18px */
`

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`
const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const FormSpacer = styled.div`
  height: ${({ theme }) => theme.spacing.medium};
`

const WellhubRegisterContainer = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const [nameBlurred, setNameBlurred] = useState(false)
  const [emailBlurred, setEmailBlurred] = useState(false)
  const [passwordBlurred, setPasswordBlurred] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
  const [error, setError] = useState('')
  const email = useSelector(Selectors.getWellhubEmail)
  const [formData, setFormData] = useState({
    firstName: '',
    email: email ?? '',
    password: ''
  })

  const formHasError = useMemo(() => {
    return !!emailErrorMessage || !!passwordErrorMessage || !!nameErrorMessage
  }, [emailErrorMessage, passwordErrorMessage, nameErrorMessage])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (formData.email === null) {
      setError(t('errors.emailRequired').toString())
      setLoading(false)
      return
    }

    dispatch({
      type: ActionType.WELLHUB_REGISTER_WITH_EMAIL_AND_PASSWORD,
      name: formData.firstName,
      email: formData.email,
      password: formData.password,
      reject: handleSubmissionError
    })
  }

  const handleSubmissionError = (error: Error) => {
    setError(error.message)
    setLoading(false)
  }

  const handleChange = (event: any) => {
    setSubmitted(false)

    const value = event.target.value.trim()
    const name = event.target.name

    setFormData({ ...formData, [name]: value })

    if (name === 'email') {
      const emailError = validateEmail(value)
      setEmailErrorMessage(emailError ?? '')
    } else if (name === 'password') {
      const passwordError = validatePassword(value)
      setPasswordErrorMessage(passwordError ?? '')
    } else if (name === 'firstName') {
      const nameError = validateName(value)
      setNameErrorMessage(nameError ?? '')
    }
  }

  const shouldShowEmailError = emailBlurred || submitted
  const shouldShowPasswordError = passwordBlurred || submitted
  const shouldShowNameError = nameBlurred || submitted

  return (
    <Center>
      <MaxWidth>
        <Header>
          <Ellipse />
          <LogoWrapper>
            <BodyFastLogo src={images.LogoWhite} alt="BodyFast Logo" />
            <Divider />
            <WellhubLogo src={images.wellhubLogoWhite} alt="Wellhub Logo" />
          </LogoWrapper>
        </Header>
        <TitleWrapper>
          <Subtitle>{t('wellhub.setPassword')}</Subtitle>
          <Title>{t('wellhub.andRegister')}</Title>
        </TitleWrapper>
        {loading ? (
          <LoadingWrapper>
            {' '}
            <ClipLoader color={'white'} size={20} speedMultiplier={1} />{' '}
          </LoadingWrapper>
        ) : (
          <ContentWrapper>
            {error && <Error>{error}</Error>}
            <form onSubmit={handleSubmit}>
              <FormRow>
                <IconInput
                  icon={<FaUser />}
                  type='text'
                  name='firstName'
                  placeholder={t('forms.name')!}
                  value={formData.firstName}
                  onChange={handleChange}
                  autoCapitalize={true}
                  onBlur={() => setNameBlurred(true)}
                  onFocus={() => setNameBlurred(false)}
                  hasError={shouldShowNameError && !!nameErrorMessage}
                  isInputValid={!validateName(formData.firstName)}
                  maxLength={MAX_NAME_LENGTH}
                />
                {shouldShowNameError && nameErrorMessage && <Error>{nameErrorMessage}</Error>}
              </FormRow>
              <FormSpacer />
              <FormRow>
                <IconInput
                  icon={<FaEnvelope />}
                  name='email'
                  value={formData.email}
                  placeholder={t('forms.email')!}
                  onChange={handleChange}
                  onBlur={() => setEmailBlurred(true)}
                  onFocus={() => setEmailBlurred(false)}
                  hasError={shouldShowEmailError && !!emailErrorMessage}
                  isInputValid={isValidEmail(formData.email)}
                />
                {shouldShowEmailError && emailErrorMessage && <Error>{emailErrorMessage}</Error>}
              </FormRow>
              <FormSpacer />
              <FormRow>
                <IconInput
                  icon={<FaLock />}
                  type='password'
                  name='password'
                  placeholder={t('forms.password')!}
                  value={formData.password}
                  onChange={handleChange}
                  onBlur={() => setPasswordBlurred(true)}
                  onFocus={() => setPasswordBlurred(false)}
                  hasError={shouldShowPasswordError && !!passwordErrorMessage}
                  isInputValid={!validatePassword(formData.password)}
                />
                {shouldShowPasswordError && passwordErrorMessage && <Error>{passwordErrorMessage}</Error>}
              </FormRow>
              <FormRow>
                <Button
                  type="submit"
                  theme="outline"
                  primaryColor="white"
                  disabled={formHasError}
                  margin="32px 0 0 0"
                >
                  {t('forms.registerWithEmail')} →
                </Button>
              </FormRow>
            </form>
          </ContentWrapper>
        )}
      </MaxWidth>
    </Center>
  )
}

export default WellhubRegisterContainer
