import { FirebaseError } from '@firebase/util'
import { BodyFastAction } from '.'
import { ActionType } from './ActionTypes'

export interface WellhubState {
  gympass_user_id: string | null
  email: string | null
}

export interface IFetchWellhubRegistrationDetailsAction { type: ActionType.FETCH_WELLHUB_REGISTRATION_DETAILS, wellhubRegistrationId: string, resolve: (email: string) => void, reject: (error: Error) => void }
export interface ISetWellhubRegistrationDetailsAction { type: ActionType.SET_WELLHUB_REGISTRATION_DETAILS, gympass_user_id: string, email: string | null }
export interface IWellhubCheckUserExistsAction { type: ActionType.WELLHUB_CHECK_USER_EXISTS, email: string }
export interface IWellhubSignInAction { type: ActionType.WELLHUB_SIGN_IN, password: string, reject: (error: FirebaseError | Error) => void, resolve: () => void }
export interface IWellhubRegisterNewUser { type: ActionType.WELLHUB_REGISTER_WITH_EMAIL_AND_PASSWORD, email: string, password: string, name: string, reject: (error: FirebaseError | Error) => void }

export type WellhubAction =
  | ISetWellhubRegistrationDetailsAction
  | IFetchWellhubRegistrationDetailsAction
  | IWellhubCheckUserExistsAction
  | IWellhubSignInAction
  | IWellhubRegisterNewUser

const INITIAL_STATE: WellhubState = {
  gympass_user_id: null,
  email: null
}

export const WellhubReducer = (state: WellhubState = INITIAL_STATE, action: BodyFastAction): WellhubState => {
  switch (action.type) {
    case ActionType.WELLHUB_CHECK_USER_EXISTS: {
      return { ...state, email: action.email }
    }
    case ActionType.SET_WELLHUB_REGISTRATION_DETAILS: {
      return { ...state, gympass_user_id: action.gympass_user_id, email: action.email }
    }
    default:
      return state
  }
}
