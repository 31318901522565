export const theme = {
  colors: {
    primary: '#10b294',
    secondary: '#fb910e',
    secondaryLight: '#F1AE31',
    primaryDark: '#0d9077',
    text: '#787369',
    lightText: '#787369',
    lightGray: '#d6d8dd',
    lighterGray: '#f0f0f0',
    packageLightBlue: '#66C2F2',
    packageDarkBlue: '#3498DB',
    packageLightGreen: '#66D4A8',
    packageDarkGreen: '#2ECC71',
    white: '#fdfdfd',
    transparent: 'transparent',
    green50: '#F0FDF9',
    green100: '#CBFCEE',
    green200: '#97F8DE',
    green300: '#5BEDCB',
    green400: '#2AD7B4',
    green500: '#10B294',
    green600: '#0A977F',
    green700: '#0D7868',
    green800: '#105F54',
    green900: '#124F46',
    green950: '#03302C',
    red50: '#FDF5F3',
    red100: '#FBE9E5',
    red200: '#F9D6CF',
    red300: '#F3BAAE',
    red400: '#EA917F',
    red500: '#DC644C',
    red600: '#CA5038',
    red700: '#A9412C',
    red800: '#8C3928',
    red900: '#753427',
    red950: '#3F1810',
    yellow50: '#FFFAED',
    yellow100: '#FFF3D5',
    yellow200: '#FFE3A9',
    yellow300: '#FECE73',
    yellow400: '#FDAE3A',
    yellow500: '#FB910E',
    yellow600: '#EC770A',
    yellow700: '#C45B0A',
    yellow800: '#9B4711',
    yellow900: '#7D3C11',
    yellow950: '#441D06',
    grey50: '#F8F8F8',
    grey100: '#F0F0F0',
    grey200: '#DCDCDC',
    grey300: '#BDBDBD',
    grey400: '#989898',
    grey500: '#7C7C7C',
    grey600: '#656565',
    grey700: '#525252',
    grey800: '#464646',
    grey900: '#3D3D3D',
    grey950: '#292929'
  },
  spacing: {
    zero: '0px',
    xsmall: '5px',
    small: '10px',
    medium: '20px',
    large: '30px',
    xLarge: '40px'
  },
  fontSizes: {
    xsmall: '0.7em',
    small: '0.85em',
    medium: '1em',
    large: '1.5em',
    xLarge: '2.5em'
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1200px'
  },

  maxWidth: '576px'
}

type Theme = typeof theme

declare module 'styled-components' {
  export interface DefaultTheme extends Theme { }
}
