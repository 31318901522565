import styled from 'styled-components'
import { images } from 'images'
import IconInput from 'Components/IconInput'
import { FaLock } from 'react-icons/fa'
import { useState } from 'react'
import Button from 'Components/Button'
import { ClipLoader } from 'react-spinners'
import { useDispatch } from 'store'
import { ActionType, Selectors } from 'Reducers'
import ResetPasswordModal from 'Components/Login/ResetPassword'
import { useSelector } from 'react-redux'
import { showToast } from 'Components/Toast'
import { validatePassword } from 'Lib'
import { FirebaseError } from '@firebase/util'
import { useTranslation } from 'react-i18next'

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const MaxWidth = styled.div`
  max-width: 400px;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing.small};
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  position: relative;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-left: 12px;
  margin-right: 12px;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
`

const Title = styled.h1`
  color: var(--Colors-Green-50, #f0fdf9);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const Divider = styled.div`
  width: 2px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 15px;
  z-index: 1;
`

const BodyFastLogo = styled.img`
  margin-top: 4px;
  width: 120px;
  z-index: 1;
`

const WellhubLogo = styled.img`
  width: 120px;
  z-index: 1;
`

const Ellipse = styled.div`
  position: absolute;
  top: -92px;
  width: 485px;
  height: 290px;
  background-color: green;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green600};
  opacity: 0.32;
  z-index: 0;
`

const PasswordError = styled.span`
  margin-top: 10px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.red800};
  padding: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 150%; /* 18px */
`

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%,
  width: 100%,
  align-items: center;
  justify-content:center;
  margin-top: 50px;
`
const ResetPasswordText = styled.p`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.white};
  padding-top: ${({ theme }) => theme.spacing.zero};
  margin-left: auto;
`

const WellhubWelcomeBackContainer = () => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showPasswordResetModal, setShowPasswordResetModal] = useState<boolean>()
  const email = useSelector(Selectors.getWellhubEmail)
  const { t } = useTranslation()

  const handleSubmit = async () => {
    if (!password || password === '') {
      const error = t('errors.passwordRequired')
      setPasswordError(error)
      return
    }

    setLoading(true)

    if (!passwordError) {
      dispatch({
        type: ActionType.WELLHUB_SIGN_IN,
        password,
        reject: (error) => {
          handleSubmitRequestError(error)
          setLoading(false)
        },
        resolve: () => setLoading(false)
      })
    } else {
      return false
    }
  }

  const handlePasswordChange = (value: string) => {
    setPassword(value)

    setPasswordError(validatePassword(value) || null)
  }

  const handleSubmitRequestError = (error: FirebaseError | Error) => {
    if (error instanceof FirebaseError) {
      const errorMessage = t(`FirebaseError.${error.code}`, { ns: 'translation', defaultValue: t('FirebaseError.auth/unknown', { ns: 'translation' }) })
      showToast('error', errorMessage)
    }

    setLoading(false)
  }

  return (
    <Center>
      <MaxWidth>
        <Header>
          <Ellipse />
          <LogoWrapper>
            <BodyFastLogo src={images.LogoWhite} alt="BodyFast Logo" />
            <Divider />
            <WellhubLogo src={images.wellhubLogoWhite} alt="Wellhub Logo" />
          </LogoWrapper>
        </Header>
        <TitleWrapper>
          <Title>{t('wellhub.welcomeBack')}</Title>
        </TitleWrapper>
        {loading ? (
          <LoadingWrapper>
            {' '}
            <ClipLoader color={'white'} size={20} speedMultiplier={1} />{' '}
          </LoadingWrapper>
        ) : (
          <ContentWrapper>
            <IconInput
              icon={<FaLock />}
              type="password"
              name='password'
              placeholder={t('forms.password') ?? 'Password'}
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              onBlur={() => { }}
              onFocus={() => { }}
              hasError={!!passwordError}
              isInputValid={!validatePassword(password)}
            />
            {passwordError && <PasswordError>{passwordError}</PasswordError>}
            <ResetPasswordText onClick={() => setShowPasswordResetModal(true)}>
              {t('forms.forgotPassword')}
            </ResetPasswordText>
            <Button
              type="submit"
              theme="outline"
              primaryColor="white"
              margin="50px 0 0 0"
              onClick={handleSubmit}
              disabled={!!passwordError}
            >
              {t('wellhub.loginNow')} →
            </Button>
          </ContentWrapper>
        )}
        {showPasswordResetModal &&
          <ResetPasswordModal
            closeModal={() => setShowPasswordResetModal(false)}
            sendResetPasswordEmail={(email: string) => {
              dispatch({
                type: ActionType.RESET_PASSWORD_REQUEST,
                email,
                onError: (errorMessage: string) => {
                  showToast('error', errorMessage)
                },
                onSuccess: () => {
                  showToast('success', t('resetPasswordModal.successMessage'))
                }
              })
            }}
            email={email ?? ''}
          />}
      </MaxWidth>
    </Center >
  )
}

export default WellhubWelcomeBackContainer
