import MoEngage from '@moengage/web-sdk'
// eslint-disable-next-line @typescript-eslint/naming-convention
const { initialize, destroy_session, add_unique_user_id, add_user_attribute, add_email, add_gender, add_user_name } = MoEngage

// Types
export type MoEngageCustomAttribute =
    | 'account_created'
    | 'age'
    | 'age_range'
    | 'app_signup'
    | 'bmi'
    | 'country'
    | 'coach_user'
    | 'experience'
    | 'fasting_plan'
    | 'shown_trophies'
    | 'first_week_started'
    | 'goal_weight'
    | 'language'
    | 'newsletter'
    | 'bodyfast_account_email'
    | 'personal_goals'
    | 'web_signup'

const APP_ID = '4GBMW6298OOO23UGL59NXDCT'

export const initializeMoEngage = () => {
  try {
    // app_id is the MoEngage App ID
    // cluster is the data center where the data is stored
    // debug_logs is the level of logs to be printed 0 - means no logs and also means that it'll work in production
    // why 2 and not 1 or something else? What a great question thank you! I found that 2 has enough logs to help with debugging.
    // Also please see https://developers.moengage.com/hc/en-us/articles/17967935599124-Debugging-Mode
    // where MoEngage suggests to use 2 for debugging
    initialize({
      app_id: APP_ID,
      cluster: 'DC_2',
      debug_logs: 0,
      disable_web_push: true // https://developers.moengage.com/hc/en-us/articles/360060819792-Configure-and-Integrate-Web-Push#disable-web-push
    })
  } catch (error) {
    logError('web_moEngageInitError', error, undefined, true)
  }
}

/* We'd use this function if we want to log a user event in webapp for MoEngage
export const logMoEngageUserEvent = (eventName: string, eventAttributes?: any) => {
  if (!MoEngage) {
    return
  }
  const attributes = { eventAttributes }
  track_event(eventName, attributes)
}
*/

/*
  For the time being we are letting backend to handle the MoEngage User Attributes.
  IMPORTANT: In case we want to revert this please make sure to handle coach_user attribute same way it is handled in firebase/functions/src/customUtils/moEngageUtils.ts > handleMoEngageUserUpdate(uid, user, userRef)
  Same goes for mobile!
*/

/*
export const updateMoEngageUserAttributes = (user: IUser) => {
  // Standard Attributes
  add_email(user.newsletterEmail ?? user.providerData?.email)
  add_gender(user.personal?.gender)
  add_user_name(user.personal?.name)

  // Custom Attributes
  const ageRange = user.personal?.ageRange ?? mapAgeToAgeRange(user.personal?.age)
  setCustomMoEngageAttribute('age_range', ageRange)

  const bmi = user.personal?.height && user.stats?.weight?.[0]?.value ? calculateBMI(user.personal.height, user.stats?.weight?.[0]?.value) : null
  const formattedBMI = bmi ? Math.round(bmi) : null
  setCustomMoEngageAttribute('bmi', formattedBMI)

  const { goalWeight, unitSystem } = user.personal ?? {}
  const formattedGoalWeight = goalWeight && unitSystem ? getFormattedWeight(goalWeight, unitSystem) : null
  setCustomMoEngageAttribute('goal_weight', formattedGoalWeight)

  setCustomMoEngageAttribute('account_created', user.created)
  setCustomMoEngageAttribute('bodyfast_account_email', user.providerData?.email)
  setCustomMoEngageAttribute('age', user.personal?.age)
  setCustomMoEngageAttribute('app_signup', !!user.isAppUser)
  setCustomMoEngageAttribute('country', user.country)
  setCustomMoEngageAttribute('coach_user', (!!user.subscription || !!user.freeSubscription)) // This shouldn't remain as (!!user.subscription || !!user.freeSubscription)) Check the comment above
  setCustomMoEngageAttribute('experience', user?.personal?.initialExperience)
  setCustomMoEngageAttribute('language', user.lang)
  setCustomMoEngageAttribute('newsletter', !!user.newsletter)
  setCustomMoEngageAttribute('personal_goals', user.personal?.personalGoals)
  setCustomMoEngageAttribute('web_signup', !!user.isWebUser)
}

const setCustomMoEngageAttribute = (attribute: MoEngageCustomAttribute, value: any) => {
  add_user_attribute(attribute, value)
}
*/

export {
  add_unique_user_id as login,
  destroy_session as logout
}
